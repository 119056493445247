<template>
    <sui-modal v-model="open" :closeIcon="true" v-if="mailbox">

        <sui-modal-header v-if="!mailbox.id">Add mailbox</sui-modal-header>
        <sui-modal-header v-else>Edit mailbox {{ mailbox.name }}</sui-modal-header>

        <sui-modal-content scrolling>
            <div class="ui grid form">
                <div class="sixteen wide column">
                    <div :class="!mailbox.name && error ? 'field error' : 'field'">
                        <label>Name</label>
                        <input type="text" v-model="mailbox.name">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div :class="((mailbox.email === '' && error) || emailError) ? 'field error' : 'field'">
                        <label>Email</label>
                        <input type="email" v-model="mailbox.email">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div :class="(mailbox.day_limit === '' || mailbox.day_limit < 10 || mailbox.day_limit > 240 && error) ? 'field error' : 'field'">
                        <label>Day limit</label>
                        <input type="number" v-model="mailbox.day_limit">
                    </div>
                </div>

                <div class="sixteen wide column">
                    <div class="field">
                        <label>Signature</label>
                        <ckeditor :editor="editor" v-model="mailbox.signature" :config="editorConfig"></ckeditor>
                    </div>
                </div>
            </div>
        </sui-modal-content>

        <sui-modal-actions>
            <div class="ui black deny button" @click="open=false">
                Cancel
            </div>
            <div class="ui right labeled icon positive button" @click="saveMailbox">
                Save<i class="checkmark icon"></i>
            </div>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>


    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';

    export default {

        data() {
            return {
                open: false,

                mailbox: null,

                defaultParams: {
                    name: '',
                    email: '',
                    day_limit: 10,
                    signature: '',
                },

                loader: null,
                error: false,
                emailError: false,

                editor: ClassicEditor,
                editorConfig: {
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        Strikethrough,
                        LinkPlugin,
                        ParagraphPlugin,
                    ],

                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'link',
                            'undo',
                            'redo'
                        ]
                    }
                },
            };
        },

        mounted() {
            this.mailbox = this.defaultParams
        },

        methods: {
            show()
            {
                this.mailbox = Object.assign({}, this.defaultParams)
                this.error = false
                this.emailError = false

                this.open = true
            },

            saveMailbox()
            {
                if (!this.mailbox.name || this.mailbox.email === ''
                    || this.mailbox.day_limit < 10 || this.mailbox.day_limit > 240)
                {
                    this.error = true
                    return
                }

                this.error = false
                this.emailError = false

                if (this.mailbox.id)
                    this.updateMailbox()
                else
                    this.createMailbox()
            },

            createMailbox()
            {
                this.loader = this.$loading.show()

                this.$http.post('mailboxes/', this.mailbox).then(response => {
                    this.$emit('created', response.data)
                    this.open = false

                }).catch(error => {
                    console.log(this.errors)

                }).then(response => {
                    this.loader.hide()
                })
            },

            updateMailbox()
            {
                this.loader = this.$loading.show()

                this.$http.patch('mailboxes/' + this.mailbox.id + '/', this.mailbox).then(response => {
                    this.$emit('updated', response.data)
                    this.open = false

                }).catch((response) => {
                    if (response.response.data.email)
                        this.emailError = true

                }).then(response => {
                    this.loader.hide()
                })
            },
        }
    }
</script>

<style scoped>
    .datepicker .vdp-datepicker__calendar {
        position: absolute;
        top: -282px;
    }

    .multi_select
    {
        height: 100px !important;
    }
</style>
