import { render, staticRenderFns } from "./ArticlesPublishedTable.vue?vue&type=template&id=74856b22&"
import script from "./ArticlesPublishedTable.vue?vue&type=script&lang=js&"
export * from "./ArticlesPublishedTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ArticlesPublishedTable.vue"
export default component.exports