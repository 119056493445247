<template>
    <div class="ui container">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Imported lists</h1>
            </div>
            <div class="column right aligned">
                <ImportCsvWithMailList class="ui button"></ImportCsvWithMailList>
            </div>
        </div>

        <div class="ui secondary pointing menu">
            <a :class="!archive ? 'item active' : 'item'" @click="archive=false">
                In work
            </a>
            <a :class="archive ? 'item active' : 'item'" @click="archive=true">
                Archive
            </a>
        </div>

        <table class="ui celled table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Rating</th>
                <th>Domains in file</th>
                <th>Domains in list</th>
                <th v-if="!archive">Domains in campaign</th>
                <th>Empties</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(list, index) in lists" :key="list.id" :class="(list.checked_empties_status === 'in progress') ? 'positive' : ''">
                <td>
                    <div v-if="selectedIndex === index && editMode === true" class="ui form field"><input v-model="list.name" required></div>
                    <router-link v-else :to="'/lists/' + list.id" class="item">{{ list.name }}</router-link>
                </td>
                <td>{{ list.rating }}+</td>
                <td>{{ list.items_in_file }}</td>
                <td>{{ list.domains_count }}</td>
                <td v-if="!archive">{{ list.domains_in_list_count }}</td>
                <td>
                    <span v-if="list.checked_empties_status === 'in progress'">{{ list.empties_checked }} / {{ list.empties_to_check }} ({{ calculatePercent(list.empties_checked, list.empties_to_check) }}%)</span>
                    <span v-else-if="list.checked_empties_status === 'finished'">{{ list.checked_empties_status }} ({{ list.added_contacts }}&nbsp;/&nbsp;{{ list.empties_to_check }}&nbsp;added)</span>
                    <span v-else-if="list.checked_empties_status === 'error'">{{ list.checked_message }}</span>
                    <span v-else>{{ list.checked_empties_status }}</span>
                </td>
                <td>
                    <div class="ui small basic icon buttons">
                        <router-link :to="'/lists/' + list.id" class="ui button"><i class="eye icon"></i></router-link>

                        <button v-if="selectedIndex === index && editMode === true" class="ui button" @click="saveData" data-content="Save"><i class="save icon"></i></button>
                        <button v-else class="ui button" @click="editRow(index)" data-content="Edit"><i class="edit icon"></i></button>
                        <button class="ui button" @click="archiveList(index)" data-content="Archive">
                            <i v-if="!archive" class="file archive icon"></i>
                            <i v-else class="file archive outline icon"></i>
                        </button>
                        <button v-if="list.checked_empties_status === ''" class="ui button" @click="checkEmpties(index)" data-content="Do guess work"><i class="zoom icon"></i></button>
                        <a v-if="($user && $user.name === 'alex') || ($user && $user.name === 'grintender')" :href="'/api/lists/' + list.id + '/export_domains/'" class="ui button" data-content="Download domains"><i class="download icon"></i></a>
                        <button class="ui button" @click="deleteList(index)"><i class="delete icon"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <br/>
        <div>
            <form class="ui form" @submit.prevent="addList">
                <div class="inline field">
                    <input type="text" placeholder="name" v-model="listObj.name">
                    <button class="ui button" type="submit">Add list</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import DomainsForListTable from '@/components/DomainsForListTable'
    import ImportCsvWithMailList from '@/components/ImportCsvWithMailList'

    export default {

        components: {
            DomainsForListTable,
            ImportCsvWithMailList
        },

        data() {
            return {
                loader: false,
                selectedIndex: null,
                editMode: false,
                archive: false,
                lists: [],
                listObj: {
                    'name': ''
                }
            };
        },

        mounted() {
            this.loadData()

            setInterval(this.loadEmptiesProgress, 1000);
        },

        updated() {
            // Semantic popup
            $('.button').popup();
        },

        watch: {
            archive: function (val) {
                this.loadData()
            },
        },

        methods: {

            loadData()
            {
                this.loader = this.$loading.show()

                let url = 'lists/'
                if (this.archive)
                    url += '?archive=1'

                this.$http.get(url).then(response => {
                    this.lists = response.data
                    this.loader.hide()
                });
            },

            addList() {
                this.loader = this.$loading.show()

                this.$http.post('lists/', this.listObj).then(response => {
                    this.lists.push(response.data)
                    this.listObj.name = ''
                    this.loader.hide()
                });
            },

            editRow(index)
            {
                this.selectedIndex = index
                this.editMode = true
            },

            saveData()
            {
                this.loader = this.$loading.show()

                let list = this.lists[this.selectedIndex]

                let data = {
                    'name': list.name
                }

                this.$http.patch('lists/' + list.id + '/', data).then(response => {
                    this.editMode = false
                    this.selectedIndex = null
                    this.loader.hide()
                });
            },

            archiveList(index)
            {
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()

                let list = this.lists[index]

                let data = {
                    'is_archive': !this.archive
                }

                this.$http.post('lists/' + list.id + '/archive/', data).then(response => {
                    this.lists.splice(index, 1)
                }).catch(error => {
                    alert(error.response.data.error);
                }).then(response => {
                    this.loader.hide()
                })
            },

            deleteList(index) {
                // Confirmation of delete
                let confirmResult = confirm("Are you sure?");
                if (!confirmResult)
                    return

                this.loader = this.$loading.show()
                this.$http.delete('lists/' + this.lists[index].id).then(response => {
                    this.lists.splice(index, 1)
                    this.loader.hide()
                });
            },

            checkEmpties(index)
            {
                let list_id = this.lists[index].id

                this.$http.get('lists/' + list_id + '/check_empties/').then(response => {
                    this.lists[index].checked_empties_status = 'in progress'
                });
            },

            loadEmptiesProgress()
            {
                this.$http.get('lists/check_progress_in_check_empties/').then(response => {

                    for (let i in response.data) {

                        let listWithProgress = response.data[i]

                        let index = this.lists.findIndex(list => list.id === listWithProgress.id);

                        this.lists[index].empties_to_check = listWithProgress.empties_to_check
                        this.lists[index].empties_checked = listWithProgress.empties_checked
                        this.lists[index].checked_empties_status = listWithProgress.checked_empties_status
                        this.lists[index].added_contacts = listWithProgress.added_contacts
                    }
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }

        }
    }
</script>
