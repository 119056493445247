<template>
    <div class="ui container">

        <div class="ui grid two column row">
            <div class="column left aligned">
                <h1 class="ui header">Dictionaries</h1>
            </div>
            <div class="column right aligned">
                <ImportDictionaryFile></ImportDictionaryFile>
                <ExportDictionaryFile></ExportDictionaryFile>
            </div>
        </div>

        <table class="ui celled table" v-if="true">
            <thead>
            <tr>
                <th>Dictionary (length)</th>
                <th>Total</th>
                <th>Matched contacts</th>
                <th>Unmatched contacts</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Names ({{ dictStats.names_total }})</td>
                <td>{{ dictStats.contacts_total }} </td>
                <td>{{ dictStats.matched_names }} ({{ calculatePercent(dictStats.matched_names, dictStats.contacts_total) }}%)</td>
                <td>{{ dictStats.unmatched_names }} ({{ calculatePercent(dictStats.unmatched_names, dictStats.contacts_total) }}%)</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import ImportDictionaryFile from '@/components/ImportDictionaryFile'
    import ExportDictionaryFile from '@/components/ExportDictionaryFile'

    export default {

        components: {
            ImportDictionaryFile,
            ExportDictionaryFile,
        },

        data() {
            return {
                loader: null,
                dictStats: [],
            };
        },

        mounted() {
           this.loadDictionariesStats()
           setInterval(this.reloadDictionariesStats, 1000);
        },

        methods: {

            loadDictionariesStats()
            {
                this.loader = this.$loading.show()

                this.$http.get('dictionaries-stats/').then(response => {
                    this.dictStats = response.data
                    this.loader.hide()
                });
            },

            reloadDictionariesStats()
            {
                this.$http.get('dictionaries-stats/').then(response => {
                    this.dictStats = response.data
                    this.loader.hide()
                });
            },

            calculatePercent(val, total)
            {
                if (total == 0)
                    return (0).toFixed(2)

                val = parseFloat(val)
                total = parseFloat(total)

                return (val * 100 / total).toFixed(2)
            }
        },


    }
</script>
