import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import router from './router'

// Semantic
import 'semantic-ui/dist/semantic.css';
import 'semantic-ui/dist/semantic.js';

// Semantic Vue
import SuiVue from 'semantic-ui-vue';
Vue.use(SuiVue);

// Pagination
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// Infinite loading
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { /* options */ });

// Loader
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading', Loading)

// Vue moment
Vue.use(require('vue-moment'));

// Vue CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Set csrftoken
if (window.csrftoken) {
    axios.defaults.headers.common['X-CSRFToken'] = window.csrftoken
    console.log(window.csrftoken)
}
else
    console.log('No csrftoken')

Vue.prototype.$http = axios

Vue.prototype.$isLoading = false

if (window.user)
    Vue.prototype.$user = window.user

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

