<template xmlns="http://www.w3.org/1999/html">
    <div id="app">

        <div class="ui inverted fixed menu menu--styles">
            <div class="ui container">

                <div v-if="$user && ($user.isAdmin || $user.groups.indexOf('database_staff') !== -1 )" class="ui simple dropdown item">
                    <router-link to="/">Database</router-link> <i class="dropdown icon"></i>
                    <div class="menu">
                        <router-link to="/da-enrichment" class="item">DA Enrichment</router-link>
                        <router-link v-if="$user && $user.isAdmin" to="/import-files" class="item">Import files</router-link>
                        <router-link v-if="$user && $user.isAdmin" to="/dictionaries" class="item">Dictionaries</router-link>
                    </div>
                </div>

                <div v-if="$user && ($user.isAdmin || $user.groups.indexOf('campaigns_staff') !== -1 )" class="ui simple dropdown item">
                    <router-link to="/v2-campaigns">Campaigns</router-link> <i class="dropdown icon"></i>
                    <div class="menu">
                        <router-link to="/v2-subjects" class="item">Subjects</router-link>
                        <router-link to="/mail-lists" class="item">Mail lists</router-link>
                        <router-link to="/recipients" class="item">All recipients</router-link>
                        <router-link to="/lists" class="item">Imported lists</router-link>
                        <router-link to="/templates" class="item">Templates</router-link>
                        <router-link to="/campaigns" class="item">Plain campaigns //legacy</router-link>
                    </div>
                </div>

                <div v-if="$user && ($user.isAdmin || $user.groups.indexOf('reports_staff') !== -1 )" class="ui simple dropdown item">
                    <router-link to="/statistic">Reports</router-link> <i class="dropdown icon"></i>

                    <div class="menu">
                        <router-link to="/statistic" class="item">Statistics</router-link>
                        <!-- TODO template AB test results -->
                        <router-link to="/established" class="item">Established</router-link>
                        <router-link to="/ab-statistic" class="item">Ab statistic</router-link>
                        <router-link to="/v2-logs" class="item">Mail logs</router-link>
                    </div>
                </div>

                <router-link v-if="$user && ($user.isAdmin || $user.groups.indexOf('opportunities_staff') !== -1 )" to="/opportunities" class="item">Call opportunities</router-link>

                <!--<router-link to="/rejected" class="item">Rejected</router-link>-->

                <router-link to="/articles" class="item">Articles</router-link>

                <div v-if="$user && ($user.name === 'grintender' || $user.name === 'alex')" class="ui simple dropdown item">
                    <span>Management</span> <i class="dropdown icon"></i>

                    <div class="menu">
                        <router-link to="/users" class="item">Users</router-link>
                        <router-link to="/finance" class="item">Finance</router-link>
                        <router-link to="/mailboxes" class="item">Mailboxes</router-link>
                    </div>
                </div>


                <div class="right menu">

                    <div class="item">
                        <div class="ui icon input">
                            <input type="text" v-model="search" placeholder="Search...">
                            <i class="circular search link icon" @click="startSearch()"></i>
                        </div>
                    </div>

                    <!--<router-link to="/import" class="item">Import</router-link>-->
                    <PitchArticle class="item"></PitchArticle>

                    <div v-if="!timer" class="item icon input">
                        <i class="circle orange icon" @click="startTimer()"></i>
                    </div>

                    <div v-else class="item icon input">
                        <i class="circle olive icon" @click="stopTimer()"></i>
                    </div>

                    <div class="item" v-if="$user">{{ $user.name }} &nbsp;|&nbsp; <a href="/logout">Logout</a></div>
                </div>

            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import PitchArticle from '@/components/PitchArticle'

    // Vue loading
    import Vue from 'vue';
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';

    Vue.use(Loading, {
        zIndex: 1999,

    })

    export default {
        components: {
            PitchArticle,
        },

        data() {
            return {
                search: '',
                loader: null,
                timer: false
            };
        },

        mounted() {
            if (this.$user.timer)
                this.timer = true

        },

        methods: {

            startSearch()
            {
                if (this.search) {
                    this.$router.push({name: 'search', params: {q: this.search}})
                    this.search = ''
                }
            },

            startTimer()
            {
                this.loader = this.$loading.show()

                this.$http.post('timer/').then(response => {
                    this.timer = true
                    this.loader.hide()
                });
            },

            stopTimer()
            {
                this.loader = this.$loading.show()

                this.$http.post('timer/stop/').then(response => {
                    this.timer = false
                    this.loader.hide()
                });
            },
        }
    }
</script>
<style>
  .menu--styles {
    border-top: 3px solid #769047 !important;
    background-color: #2e3f50 !important;
  }


    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 80px;
    }

    .user-header
    {
        top: 0px;
        padding-top: 5px;
        position: fixed;
        background: #fff;
        z-index: 101;
        width: 100%;
        height: 40px;
    }

    .user-header .right
    {
        margin-right: 15px;
    }

    /*.ui.fixed.menu, .ui[class*="top fixed"].menu {
        top: 40px;
    }*/

    .edit-field input {
        width: 70px !important;
        padding: 0 !important;
        margin: 0 !important;
        font-weight: bold;
        font-family: inherit !important;
    }

    .input-field
    {
        width: 100px !important;
    }

    .import-button {
        cursor: pointer;
    }

    .vld-overlay.is-full-page
    {
        z-index: 1999 !important;
    }

    .menu .play, .menu .stop, .menu .circle
    {
        cursor: pointer;
    }
</style>
