import Vue from 'vue'
import Router from 'vue-router'
import Domains from './views/Domains.vue'
import Search from './views/Search.vue'
import DomainDetails from './views/DomainDetails.vue'
import Established from './views/Established.vue'
import Rejected from './views/Rejected.vue'
import Opportunities from './views/Opportunities.vue'
import Lists from './views/Lists.vue'
import ListDomains from './views/ListDomains.vue'
import MailLists from './views/MailLists.vue'
import MailListRecipients from './views/MailListRecipients.vue'
import Recipients from './views/Recipients.vue'
import Statistic from './views/Statistic.vue'
import Articles from './views/Articles.vue'
import Import from './views/Import.vue'
import Users from './views/Users.vue'
import UserStatistic from './views/UserStatistic.vue'
import Templates from './views/Templates.vue'
import Campaigns from './views/Campaigns.vue'
import V2Campaigns from './views/V2Campaigns.vue'
import V2Subjects from './views/V2Subjects.vue'
import CampaignDetails from './views/CampaignDetails.vue'
import ABStatistic from './views/ABStatistic.vue'
import DAEnrichment from './views/DAEnrichment.vue'
import ImportFiles from './views/ImportFiles.vue'
import Dictionaries from './views/Dictionaries.vue'
import Finance from './views/Finance.vue'
import FinanceMainProject from './views/FinanceMainProject.vue'
import FinanceProject from './views/FinanceProject.vue'
import Mailboxes from './views/Mailboxes.vue'
import V2MailLogs from './views/V2MailLogs.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'domains',
            component: Domains,
        },
        {
            path: '/search/:q',
            name: 'search',
            component: Search,
            props: true
        },
        {
            path: '/domains/:id',
            name: 'domain-details',
            component: DomainDetails
        },
        {
            path: '/established',
            name: 'established',
            component: Established
        },
        {
            path: '/rejected',
            name: 'rejected',
            component: Rejected
        },
        {
            path: '/opportunities',
            name: 'opportunities',
            component: Opportunities
        },
        {
            path: '/lists',
            name: 'lists',
            component: Lists
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            //component: () => import(/* webpackChunkName: "about" */ './views/Lists.vue')
        },
        {
            path: '/lists/:id',
            name: 'list',
            component: ListDomains
        },

        {
            path: '/mail-lists',
            name: 'mail-lists',
            component: MailLists
        },

        {
            path: '/recipients',
            name: 'recipients',
            component: Recipients
        },

        {
            path: '/mail-lists/:id',
            name: 'mail-list',
            component: MailListRecipients
        },

        {
            path: '/statistic',
            name: 'statistic',
            component: Statistic
        },

        {
            path: '/v2-logs',
            name: 'v2-logs',
            component: V2MailLogs
        },

        {
            path: '/articles',
            name: 'articles',
            component: Articles
        },

        {
            path: '/users',
            name: 'users',
            component: Users
        },

        {
            path: '/users/:id/statistic',
            name: 'user-statistic',
            component: UserStatistic
        },

        {
            path: '/import',
            name: 'import',
            component: Import
        },

        {
            path: '/templates',
            name: 'templates',
            component: Templates
        },

        {
            path: '/campaigns',
            name: 'campaigns',
            component: Campaigns
        },

        {
            path: '/v2-campaigns',
            name: 'v2-campaigns',
            component: V2Campaigns
        },

        {
            path: '/v2-subjects',
            name: 'v2-subjects',
            component: V2Subjects
        },

        {
            path: '/campaigns/:id',
            name: 'campaignDetails',
            component: CampaignDetails
        },

        {
            path: '/ab-statistic',
            name: 'ABStatistic',
            component: ABStatistic
        },

        {
            path: '/da-enrichment',
            name: 'DAEnrichment',
            component: DAEnrichment
        },

        {
            path: '/import-files',
            name: 'ImportFiles',
            component: ImportFiles
        },

        {
            path: '/dictionaries',
            name: 'Dictionaries',
            component: Dictionaries
        },

        {
            path: '/finance',
            name: 'Finance',
            component: Finance
        },
        {
            path: '/finance/main-projects/:id',
            name: 'FinanceMainProject',
            component: FinanceMainProject
        },
        {
            path: '/finance/projects/:id',
            name: 'FinanceProject',
            component: FinanceProject
        },

        {
            path: '/mailboxes',
            name: 'mailboxes',
            component: Mailboxes
        },
    ]
})
