import { render, staticRenderFns } from "./MailListAddToArchiveModal.vue?vue&type=template&id=709ef282&"
import script from "./MailListAddToArchiveModal.vue?vue&type=script&lang=js&"
export * from "./MailListAddToArchiveModal.vue?vue&type=script&lang=js&"
import style0 from "./MailListAddToArchiveModal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MailListAddToArchiveModal.vue"
export default component.exports